import React from "react"

import Layout from "../components/layout"

import { MetaData } from "../components/meta"
import { useMedia } from "react-media"

import BiografiaCard from "../components/biografia-card"

const items = require("../data/biografia.json")
const BiografiaPage = ({ data }) => {
  const isSmallScreen = useMedia({ query: "(max-width: 599px)" })

  const location = {
    pathname: "/biografia/",
    search: "",
    hash: "",
  }

  return (
    <Layout biografia={items}>
      <MetaData location={location} />

      <div className="row">
        <div className="col-12">
          <h2>#biografia</h2>
          <p>
            Essa é um pouco da história de dedicação, trabalho e amor do
            deputado Pompeo ao trabalhismo e ao povo gaúcho. Uma história
            enraizada nesta pátria gaúcha, que testemunhou a gênese da sua
            paixão pela política e onde consolidou sua extrema dedicação à causa
            pública.
          </p>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-md-8 mx-auto">
          <ul className="timeline">


           

            {items.map((item, index) => (
             <BiografiaCard data={item} key={item.ano} />
             
             // <li
              //   key={index}
              //   className={index % 2 !== 0 ? "timeline-inverted" : null}
              // >
              //   <a name={item.ano} href="#biografia" className="sr-only">
              //     topo
              //   </a>
              //   <div className="timeline-badge warning">
              //     <i className="glyphicon glyphicon-check">{item.ano}</i>
              //   </div>
              //   <div className="timeline-panel">
              //     <div className="timeline-heading" />
                  // <img
                  //   className="img-fluid"
                  //   src={require(`../images/biografia/${item.img}.jpg`)}
                  //   alt={`${item.ano} ${item.img}`}
                  // />
              //     <div className="timeline-body">
              //       <p>{item.text}</p>
              //     </div>
              //   </div>
              // </li>
            ))}
            <a href="#biografia">voltar ao topo</a>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

// export const query = graphql`
//   query BiografiaQuery {
// 	allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "biografia"}}) {
//     edges {
//       node {
//         childImageSharp {
//           fluid(maxWidth: 600) {
//             ...GatsbyImageSharpFluid_withWebp
//           }
//         }

//       }
//     }
//   }
//   }
// `

export default BiografiaPage
