/* eslint-disable import/first */
import PropTypes from "prop-types"
import React from "react"

const BiografiaCard = ({ data }) => {
  const { ano, text, img } = data
  return (
    <div className="row">
      <div key={ano} className="col ">
        <h6 className="tag-title">{ano}</h6>

        <div className="img-wrapper img-noticias">
          <img
            className="img-fluid"
            src={require(`../images/biografia/${img}.jpg`)}
            alt={`${ano} ${img}`}
          />
        </div>

        <h6 className="mt-2">
          {/* {node.title.length >= 50 ? node.title.substr(0, 46) + ' ...' : node.title} */}
          {text}
        </h6>
      </div>
    </div>
  )
}

export default BiografiaCard

BiografiaCard.propTypes = {
  data: PropTypes.shape({
    ano: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
  }),
}
